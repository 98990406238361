import img1 from './img1.png';

const megumi = {
    name: "Megumi",
    category: "Jujutsu Kaisen",
    image: img1,
    imageList: [img1],
    age: 16,
    gender: "Male",
    looks: {
        color: "Fair",
        height: "Average", // Varies
        hairColor: "Black"
    },
    pronouns: ["he", "him", "his"],
    likes: ["Animals", "Mysteries", "Reading", "Peace", "Chess"],
    dislikes: ["Curses", "Injustice", "Unnecessary Violence", "Arrogance"],
    personality: ["Reserved", "Observant", "Calm", "Analytical", "Determined"],
    intro: "I'm Megumi, and I'll protect what's important to me."
};

export { megumi };
