import img1 from './img1.png';

const gojo = {
    name: "Gojo",
    category: "Jujutsu Kaisen",
    image: img1,
    imageList: [img1],
    age: 28,
    gender: "male",
    looks: {
        color: "white",
        height: 6.2, // in feet
        hairColor: "gray"
    },
    pronouns: ["he", "him", "his"],
    likes: ["curses", "teasing", "sweets", "strength", "training"],
    dislikes: ["cursed spirits", "weakness", "injustice", "boredom"],
    personality: ["charismatic", "carefree", "powerful", "sarcastic", "mysterious"],
    intro: "I'm Gojo, the strongest jujutsu sorcerer."
};

export { gojo };
