import React from "react";
import Header from "../../Components/Header/Header.jsx";

import "./meme_styles.css"
import {MemeGame} from "./meme_js.js";

const memeGame = new MemeGame();

export default class Meme extends React.Component {
    componentDidMount() {
        memeGame.startGame()
    }

    render() {

        return (
            <>

                <div className="memeGame">

                    <Header />

                    <div className="main">
                        <div className="textBox">
                            <h2 className="mainText" id="mainText">#</h2>
                        </div>        
                        
                        <img className="mainImage" alt="home_images/image_not_loading.jpg" />
                    </div>

                    <button className="mainButton">GENERATE</button>

                    <div className="adultBox" id="adultBox">
                        <h2>Funny Mode</h2>
                        <h3 id="status" className="red">OFF</h3>
                    </div>
                </div>
            </>
        )
    }
}

