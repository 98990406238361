import img1 from './img1.png';

const levi = {
    name: "Levi",
    category: "Attack on Titan",
    image: img1,
    imageList: [img1],
    age: 30,
    gender: "male",
    looks: {
        color: "white",
        height: 5.3, // in feet
        hairColor: "black"
    },
    pronouns: ["he", "him", "his"],
    likes: ["cleanliness", "tea", "discipline", "order", "combat"],
    dislikes: ["titans", "dirt", "disorder", "inefficiency", "laziness"],
    personality: ["stoic", "disciplined", "calm", "sharp", "loyal"],
    intro: "I'm Levi, humanity's strongest soldier."
};

export { levi };
