import img1 from  "./memeimages/img (1).jpg"
import img2 from  "./memeimages/img (2).jpg"
import img3 from  "./memeimages/img (3).jpg"
import img4 from  "./memeimages/img (4).jpg"
import img5 from  "./memeimages/img (5).jpg"
import img6 from  "./memeimages/img (6).jpg"
import img7 from  "./memeimages/img (7).jpg"
import img8 from  "./memeimages/img (8).jpg"
import img9 from  "./memeimages/img (9).jpg"
import img10 from  "./memeimages/img (10).jpg"
import img11 from  "./memeimages/img (11).jpg"
import img12 from  "./memeimages/img (12).jpg"
import img13 from  "./memeimages/img (13).jpg"
import img14 from  "./memeimages/img (14).jpg"
import img15 from  "./memeimages/img (15).jpg"
import img16 from  "./memeimages/img (16).jpg"
import img17 from  "./memeimages/img (17).jpg"
import img18 from  "./memeimages/img (18).jpg"
import img19 from  "./memeimages/img (19).jpg"
import img20 from  "./memeimages/img (20).jpg"
import img21 from  "./memeimages/img (21).jpg"
import img22 from  "./memeimages/img (22).jpg"
import img23 from  "./memeimages/img (23).jpg"
import img24 from  "./memeimages/img (24).jpg"
import img25 from  "./memeimages/img (25).jpg"
import img26 from  "./memeimages/img (26).jpg"
import img27 from  "./memeimages/img (27).jpg"
import img28 from  "./memeimages/img (28).jpg"
import img29 from  "./memeimages/img (29).jpg"
import img30 from  "./memeimages/img (30).jpg"
import img31 from  "./memeimages/img (31).jpg"
import img32 from  "./memeimages/img (32).jpg"
import img33 from  "./memeimages/img (33).jpg"
import img34 from  "./memeimages/img (34).jpg"
import img35 from  "./memeimages/img (35).jpg"
import img36 from  "./memeimages/img (36).jpg"
import img37 from  "./memeimages/img (37).jpg"
import img38 from  "./memeimages/img (38).jpg"
import img39 from  "./memeimages/img (39).jpg"
import img40 from  "./memeimages/img (40).jpg"
import img41 from  "./memeimages/img (41).jpg"
import img42 from  "./memeimages/img (42).jpg"
import img43 from  "./memeimages/img (43).jpg"
import img44 from  "./memeimages/img (44).jpg"
import img45 from  "./memeimages/img (45).jpg"
import img46 from  "./memeimages/img (46).jpg"
import img47 from  "./memeimages/img (47).jpg"
import img48 from  "./memeimages/img (48).jpg"
import img49 from  "./memeimages/img (49).jpg"
import img50 from  "./memeimages/img (50).jpg"
import img51 from  "./memeimages/img (51).jpg"
import img52 from  "./memeimages/img (52).jpg"
import img53 from  "./memeimages/img (53).jpg"



export class MemeGame {

    constructor() {
        this.isInitialized = false;
        this.status_count = 1;
        this.eventList = null;
        this.mode = "kid";
        this.adultList = []
        this.numberOfImages = 24

        this.whoList = [
            "Me", 
            "My friend",
            "Donald Trump", 
            "My friend group", 
            "Kim Jong-un", 
            "My parents", 
            "God", 
            "The devil", 
            "My siblings"];

        this.kid = [
            "i finally finish a project I've been working on for months", 
            "someone cancels plans we made weeks ago",
            "I die in the only video game I'm good at", 
            "I get arrested", 
            "there's an invasion of aliens", 
            "batman pulls up", 
            "I nerd out", 
            "I buy a bugatti", 
            "its my birthday", 
            "there's no snow day", 
            "I blush", 
            "I confess my love", 
        ];


        this.adult = [
            "shit gets real",
            "they find the kids in my basement", 
            "I plan to nuke north korea", 
            "they find da weed", 
            "mfs pull up my house", 
            "the c4 I planted at my school goes off", 
            "they find the femboy socks", 
            "the gay male strippers pull up",  
            "I say im not gay", 
            "females pull up to the party",
            "I bend over",
            "the short mf starts talking", 
            "the fat mf starts talking shit to me", 
            "they suspect im gay", 
        ]

        this.imageList = [
            img1,
            img2,
            img3,
            img4,
            img5,
            img6,
            img7,
            img8,
            img9,
            img10,
            img11,
            img12,
            img13,
            img14,
            img15,
            img16,
            img17,
            img18,
            img19,
            img20,
            img21,
            img22,
            img23,
            img24,
            img25,
            img26,
            img27,
            img28,
            img29,
            img30,
            img31,
            img32,
            img33,
            img34,
            img35,
            img36,
            img37,
            img38,
            img39,
            img40,
            img41,
            img42,
            img43,
            img44,
            img45,
            img46,
            img47,
            img48,
            img49,
            img50,
            img51,
            img52,
            img53,


        ];

        // const posibleText = document.querySelector(".posibile");
        // posibleText.innerHTML = (`There are ${imageList.length * (kid.length + adult.length) * whoList.length} posible combinations`);

    }

    makeLists() {
        for (let i = 0; i <= this.adult.length - 1 ; i++) {
            this.adultList.push(this.adult[i]);
        }

        // for (let i = 0; i <= 53 ; i++) {
        //     this.imageList.push(eval("img" + (i + 1)));
        // }
        // console.log(this.imageList)
    
        // for (let i = 0; i <= this.kid.length - 1 ; i++) {
        //     this.adultList.push(this.kid[i]);
        // }
    }

    generateMeme() {
        if (this.mode === "kid") {
            this.eventList = this.kid;
        } else {
            this.eventList = this.adultList;
        }

        let who = this.whoList[Math.floor(Math.random() * this.whoList.length)];
        let action = this.eventList[Math.floor(Math.random() * this.eventList.length)];
        let image = this.imageList[Math.floor(Math.random() * this.imageList.length)];

        if (this.action === undefined) {
            while (this.action === undefined) {
                this.action = this.eventList[Math.floor(Math.random() * this.eventList.length)];
            }
        }
  
        this.mainText.innerHTML = `${who} when ${action}:`;
        this.mainImage.src = image;
    }

     
     
    bindEvents() {
        this.genorateButton.addEventListener("click",  (e) => {
            this.generateMeme()
        });

   
        this.adultBox.addEventListener("click", () => {
            this.status_count += 1;
            console.log("ok")
            const statusTag = document.getElementById("status")
            if (this.status_count % 2 === 0) {
                statusTag.innerHTML = "ON"
                statusTag.classList.add("green")
                statusTag.classList.remove("red")
                this.mode = "adult"
            } else {
                statusTag.innerHTML = "OFF"
                statusTag.classList.remove("green")
                statusTag.classList.add("red")
                this.mode = "kid";
            }
        })
    
    
        window.addEventListener('keydown', (event) => {
            if (event.code === "Space") {
                this.generateMeme()
            }

            if (event.key === "q" || event.key === "Q") {
                this.mode = "kid"
                
                const adultBox = document.getElementById("adultBox")
                adultBox.classList.add("hide")
            }  
        });
    }

    // createImages() {
    //     for (let i = 0; i < this.numberOfImages; i++) {
    //         // let path = "/memeimages/img (" + (i + 1) + ").jpg"
    //         let path = "./memeimages/img (" + (i + 1) + ").jpg"

    //         this.imageList.push(path)
    //     }
    // }

    startGame() {
        if (this.isInitialized) return;
        else this.isInitialized = true;
            
        
        this.genorateButton = document.querySelector(".mainButton");
        this.mainImage = document.querySelector(".mainImage");
        this.mainText = document.getElementById("mainText");
        this.adultBox = document.querySelector(".adultBox")
        this.statusTag = document.getElementById("status")

        this.makeLists()
        // this.createImages()
        this.bindEvents()
        this.generateMeme()
    }

}