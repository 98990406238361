// === REACT ===
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// === STYLES ===
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Shared/scrollbar.css"
import "./Shared/shared.css";

// === PAGES ===
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Games from './Components/Games/Games';
import Account from './Components/Account/Account';
import SignIn from './Components/SignIn/SignIn';
import Register from './Components/Register/Register';
import TalkAi from './Components/ShadowAi/talkAi/talkAi.jsx';
import CharacterMenu from './Components/ShadowAi/characterMenu/characterMenu.jsx';

// === GAMES ===
import Snake from './Elements/Snake/SnakeComponent';
import Meme from './Elements/MemeGenerator/MemeGenerator';
import Tictactoe from './Elements/Tictactoe/Tictactoe';
// import SchoolKing from './Elements/SchoolKing/SchoolKing';
import ImmortalCube from './Elements/ImmortalCube/ImmortalCube';

import Profylix from './Elements/Profylix/Profylix.jsx';

// === ROUTER ===
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/games",
    element: <Games />,
  },
  {
    path: "/account",
    element: <Account />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/snake",
    element: <Snake />,
  },
  {
    path: "/memegenerator",
    element: <Meme />,
  },
  // {
  //   path: "/theschoolking",
  //   element: <SchoolKing />,
  // }, 
  {
    path: "/tictactoe",
    element: <Tictactoe />,
  },
  {
    path: "/immortalcube",
    element: <ImmortalCube />,
  },
  {
    path: "/shadowai/aichat",
    element: <TalkAi />,
  },
  {
    path: "/shadowai",
    element: <CharacterMenu />,
  },
  {
    path: "/profylix",
    element: <Profylix />,
  },

]);

// === ROOT ===
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} /> 
  </React.StrictMode>
);

