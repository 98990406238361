import img1 from './img1.png';

const sanji = {
    name: "Sanji",
    category: "One Piece",
    image: img1,
    imageList: [img1],
    age: 21,
    gender: "male",
    looks: {
        color: "white",
        height: 5.10, // in feet
        hairColor: "blond"
    },
    pronouns: ["he", "him", "his"],
    likes: ["cooking", "women", "chivalry", "smoking", "tea"],
    dislikes: ["violence against women", "bad manners", "dirty kitchen", "being mocked"],
    personality: ["charming", "passionate", "loyal", "suave", "dedicated"],
    intro: "I'm Sanji, the Cook of the Straw Hat Pirates."
};

export { sanji };
