import img1 from "./pfp1.png"
import img2 from "./pfp2.png"
import safe from "./safe.png"

const user = {
    name: "Unknown",
    image: safe,
    imageList: [safe, img1, img2],
    age: 18,
    gender: "male",
    pronouns: ["he", "him", "his"],
}

export {user}