import React from "react";
import MobileBlock from "../Mobile/MobileBlock.jsx";

import "./cube.css"
import {ImmortalCubeGame} from "./cube.js";

const immortalCubeGame = new ImmortalCubeGame();


export default class Cube extends React.Component {
    componentDidMount() {
        immortalCubeGame.init()
    }

    
    render() {

        return (
            <>
                <MobileBlock />

                <div className="cubeGame">
                    <div className="startScreen" id="startScreen">
                        <h2 className="name">IMMORTAL CUBE</h2>
                        <button className="startButton" id="startButton">START</button>
                    </div>
                    <canvas id="canvas" className="hide"></canvas> 
                    <span className="material-symbols-outlined settings hide" id="settingsButton">settings</span>

                    <div className="settingsHolder hide" id="settingsHolder">
                        {/* <!-- <span className="material-symbols-outlined settings" id="settingsCloseButoon">close</span> --> */}
                        <div className="settingsBox option">
                            <div className="topSettings">

                                <div className="closeButton" id="closeButton">
                                    <span className="material-symbols-outlined">close</span>
                                </div>

                                <div className="skipLevel" id="skipLevelButton">
                                    <h2>SKIP</h2> 
                                    <span className="material-symbols-outlined">skip_next</span>
                                </div>

                            </div>
                            
                            <div className="option" id="musicSetting">
                                <h2>MUSIC</h2>
                                <div>
                                    <span className="material-symbols-outlined" id="musicOn">toggle_on</span>
                                    <span className="material-symbols-outlined hide" id="musicOff">toggle_off</span>
                                </div>
                            </div>
                            <div className="option" id="soundEfectSetting">
                                <h2>SOUND EFFECTS</h2>
                                <div>
                                    <span className="material-symbols-outlined" id="soundEfectOn">toggle_on</span>
                                    <span className="material-symbols-outlined hide" id="soundEfectOff">toggle_off</span>
                                </div>
                            </div>
                            <a href="/" className="option sgLink">
                                <h2>SHADOW GAMES</h2>
                            </a>
                            
                        </div>
                    </div>

                    <div className="gameOver hide" id="gameOver">
                        <h2 className="name">THE END</h2>
                        <h3 className="hint">PRESS R TO RESTART</h3>
                    </div>
                </div>
            </>
        )
    }
}

