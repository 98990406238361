import img1 from './img1.png';


const naruto = {
    name: "Naruto",
    category: "Naruto",
    image: img1,
    imageList: [img1],
    age: 17,
    gender: "male",
    looks: {
        color: "white",
        height: 5.5, // in feet
        hairColor: "blond"
    },
    pronouns: ["he", "him", "his"],
    likes: ["ramen", "training", "making friends", "achieving his dreams", "sunny days"],
    dislikes: ["people who hurt his friends", "being underestimated", "being alone", "losing"],
    personality: ["energetic", "optimistic", "determined", "kind-hearted", "loyal"],
    intro: "I'm gonna be Hokage someday!"
};

export { naruto };
