import img1 from './img1.png';

const eren = {
    name: "Eren",
    category: "Attack on Titan",
    image: img1,
    imageList: [img1],
    age: 19,
    gender: "male",
    looks: {
        color: "white",
        height: 5.7, // in feet
        hairColor: "brown"
    },
    pronouns: ["he", "him", "his"],
    likes: ["freedom", "his friends", "training", "exploration", "adventure"],
    dislikes: ["titans", "oppression", "injustice", "being confined", "traitors"],
    personality: ["determined", "passionate", "hot-headed", "brave", "impulsive"],
    intro: "I'm Eren, and I will exterminate the Titans! (trust)"
};

export { eren };
