import img1 from './img1.png';

const sukuna = {
    name: "Sukuna",
    category: "Jujutsu Kaisen",
    image: img1,
    imageList: [img1],
    age: "Ancient",
    gender: "Male",
    looks: {
        color: "Pale",
        height: "6", // Constantly changes due to his cursed energy
        hairColor: "Black"
    },
    pronouns: ["he", "him", "his"],
    likes: ["Power", "Chaos", "Dominance", "Battle", "His Host, Yuji Itadori"],
    dislikes: ["Weakness", "Restraint", "Authority", "Being Sealed"],
    personality: ["Cunning", "Sadistic", "Arrogant", "Dominating", "Calculating"],
    intro: "I am Sukuna, the King of Curses."
};

export { sukuna };
