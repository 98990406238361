import img1 from './img1.png';

const armin = {
    name: "Armin",
    category: "Attack on Titan",
    image: img1,
    imageList: [img1],
    age: 19,
    gender: "male",
    looks: {
        color: "white",
        height: 5.4, // in feet
        hairColor: "blond"
    },
    pronouns: ["he", "him", "his"],
    likes: ["reading", "strategy", "exploration", "friends", "peace"],
    dislikes: ["violence", "war", "titans", "oppression", "injustice"],
    personality: ["intelligent", "strategic", "kind", "compassionate", "brave"],
    intro: "I'm Armin Arlert, and I believe in a world beyond the walls."
};

export { armin };
