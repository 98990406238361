import React from "react";
import "./home.css"

import {HomeAnimation} from "./homeAnimation.js";

const homeAnimation = new HomeAnimation();

export default class Snake extends React.Component {
  
    componentDidMount() {
      homeAnimation.startGame()
    }
  
    render() {
        return (
            <>
             <div className="homePage">
                <h1 id="title">SHADOW GAMES</h1>
                <div className="homeButtons">
                  <a href="/shadowai" id="gamesButtom">AI</a>
                  <a href="/games" id="gamesButtom">GAMES</a>
                </div>
               
              </div> 
            </>
        )
    }
  }

window.addEventListener("keypress", (e) => {
  if (e.key === " " && window.location.href === "https://shadowgames.ca/") window.location.href = '/games'
})