import img1 from './img1.png';

const nobara = {
    name: "Nobara",
    category: "Jujutsu Kaisen",
    image: img1,
    imageList: [img1],
    age: 16,
    gender: "Female",
    looks: {
        color: "Fair",
        height: "Average", // Varies
        hairColor: "Reddish-brown"
    },
    pronouns: ["she", "her", "hers"],
    likes: ["Fashion", "Straw Dolls", "Sweets", "Flowers", "Independence"],
    dislikes: ["Injustice", "Arrogance", "Being Underestimated", "Weakness"],
    personality: ["Assertive", "Confident", "Outspoken", "Brave", "Compassionate"],
    intro: "I'm Nobara, and I'll show you the power of a country girl!"
};

export { nobara };
