// === ONE PIECE ===
import { luffy } from './characters/luffy/info.js';
import { zoro } from './characters/zoro/info.js';
import { robin } from './characters/robin/info.js';
import { sanji } from './characters/sanji/info.js';

// === NARUTO ===
import { naruto } from './characters/naruto/info.js';
import { sasuke } from './characters/sasuke/info.js';
import { hinata } from './characters/hinata/info.js';
import { kakashi } from './characters/kakashi/info.js';

// === ATTACK ON TITAN ===
import { eren } from './characters/eren/info.js';
import { mikasa } from './characters/mikasa/info.js';
import { armin } from './characters/armin/info.js';
import { levi } from './characters/levi/info.js';

// === JUJUTSU KAISEN ===
import { megumi } from './characters/megumi/info.js';
import { nobara } from './characters/nobara/info.js';
import { sukuna } from './characters/sukuna/info.js';
import { gojo } from './characters/gojo/info.js';

// === DEMON SLAYER: KIMETSU NO YAIBA ===
import { tanjiro } from './characters/tanjiro/info.js';
import { nezuko } from './characters/nezuko/info.js';
import { zenitsu } from './characters/zenitsu/info.js';
import { inosuke } from './characters/inosuke/info.js';

export const characters = [

    // One Piece
    luffy,
    zoro,
    robin,
    sanji,

    // Naruto
    naruto,
    sasuke,
    hinata,
    kakashi,

    // Attack on Titan
    eren,
    mikasa,
    armin,
    levi,

    // Jujutsu Kaisen
    gojo,
    sukuna,
    nobara,
    megumi,

    // Demon Slayer: Kimetsu no Yaiba
    tanjiro,
    zenitsu,
    inosuke,
    nezuko,
];
