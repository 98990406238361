import React from "react";
import Header from "../Header/Header.jsx";

import Icon from "../../Shared/images/nikita.jpg"
import "./account.css"

export default function Account() {
    return (
      <>
        <Header />

        <div className="accountPage">
            <div className="infoCard">
                <img src={Icon} alt="" />
                <div className="names">
                    <h2>Name</h2>
                    <div className="userlvl">
                        <h3>Username</h3>
                        <h4><i>LVL |</i></h4>
                    </div>
                    
                </div>
            </div>

            <div className="quests">
                <h2>QUESTS</h2>
                <div className="questsHolder">
                    <div className="task">
                        <h3>Become the school king</h3>
                        <h6>0%</h6>
                    </div>
                    <div className="task">
                        <h3>Win 5 times in TIC TAC TOE</h3>
                        <h6>0%</h6>
                    </div>
                    <div className="task">
                        <h3>Collect 20 poits in SNAKE</h3>
                        <h6>0%</h6>
                    </div>

                </div>
            </div>

            <div className="end">
                <button>LOG OUT</button>
            </div>
        </div>
      </>
    )
}