import img1 from './img1.png';

const nezuko = {
    name: "Nezuko",
    category: "Demon Slayer",
    image: img1,
    imageList: [img1],
    age: "16",
    gender: "Female",
    looks: {
        color: "White",
        height: 5.5, // Changes due to her demon form
        hairColor: "Black"
    },
    pronouns: ["she", "her", "hers"],
    likes: ["Her family", "Protecting humans", "Sleeping", "Bamboo", "Tanjiro's kindness"],
    dislikes: ["Bloodlust", "Harming humans", "Her demon urges"],
    personality: ["Kind-hearted", "Protective", "Brave", "Resilient", "Determined"],
    intro: "I'm Nezuko Kamado, a demon who fights alongside my brother."
};

export { nezuko };
