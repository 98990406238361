import React, { useRef } from 'react';
import "./profylix_css.css";
import emailjs from '@emailjs/browser';


export default function Profylix() {

    const handleClick = (event, hash) => {
        event.preventDefault();
        const element = document.getElementById(hash.substring(1));
        element.scrollIntoView({ behavior: 'smooth' });
    };

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const sendButton = document.getElementById("sendButton");

        const message = document.getElementById("message");
        if (!message.value || message.value.indexOf("@") === -1 || message.value.indexOf(".") === -1) {
            sendButton.innerHTML = "close"
            setTimeout(() => {
                sendButton.innerHTML = "send";
            }, 2000);
            return
        }; 


        emailjs.sendForm('service_2io7qel', 'template_j0hmfqk', form.current, {
            publicKey: '-36ck9OwckXCNdogc',
        })
        .then(
            () => {
            console.log('SUCCESS!');
            sendButton.innerHTML = "check"

            
            },
            (error) => {
            console.log('FAILED...', error.text);
            },
        );
    }

    const items = [
        {
            name: "Alex",
            img: "https://i.pinimg.com/736x/19/75/81/197581865c36d3b246234435acf6dab2.jpg",
            socialImg1: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/600px-LinkedIn_logo_initials.png?20140125013055",
            socialImg2: "https://cdn.pixabay.com/photo/2022/01/30/13/33/github-6980894_1280.png",
            socialImg3: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Stack_Overflow_icon.svg/768px-Stack_Overflow_icon.svg.png",
            description: "Hey, I'm Alex. I'm a programmer who loves creating websites for fun. Whether it's experimenting with new web technologies or diving into side projects, I enjoy bringing ideas to life through coding.",
            photo1: "https://www.cnet.com/a/img/resize/58b4427d5988522c975bbac22b807c73fd501af2/hub/2020/06/10/06f2b84a-2241-4567-a2a5-611ebb1e2650/gettyimages-1129377183.jpg?auto=webp&fit=crop&height=675&width=1200",
            photo2: "https://i.pinimg.com/564x/31/0e/1f/310e1f4b18b1106331b82e53583f665c.jpg",
            photo3: "https://i.pinimg.com/564x/1d/6c/11/1d6c117b9897cf74549eadc7dc3f1109.jpg"
        },
        {
            name: "Sophia",
            img: "https://i.pinimg.com/564x/e2/e1/9b/e2e19bc483b001dc7196c433b05cc268.jpg",
            socialImg1: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png",
            socialImg2: "https://upload.wikimedia.org/wikipedia/fr/7/75/Snapchat.png",
            socialImg3: "https://img.freepik.com/premium-vector/tik-tok-logo_578229-290.jpg",
            description: "Hey, I'm Sophia. I'm a girl who cherishes spending quality time with friends and discovering new experiences together. Whether it's chatting over coffee or embarking on spontaneous road trips, building meaningful connections is what makes life truly special.",
            photo1: "https://i.pinimg.com/564x/e8/f3/5c/e8f35c99f22607488443c47613f691d5.jpg",
            photo2: "https://i.pinimg.com/564x/ac/5f/25/ac5f2528efe0c753f59b088b9357187f.jpg",
            photo3: "https://i.pinimg.com/564x/0b/c2/54/0bc254807c0235d5a5e4a895e310f5b6.jpg"
        },
        {
            name: "Connor",
            img: "https://i.pinimg.com/564x/b5/25/ae/b525ae7cb74803d3e7cbc2a0b1b25335.jpg",
            socialImg1: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png",
            socialImg2: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/232px-Spotify_icon.svg.png",
            socialImg3: "https://static.vecteezy.com/system/resources/previews/023/741/147/non_2x/discord-logo-icon-social-media-icon-free-png.png",
            description: "Hey, I'm Connor. I'm a passionate gamer who loves diving into virtual worlds and exploring new adventures. From epic battles to thrilling quests, gaming is my ultimate escape and source of excitement.",
            photo1: "https://i.pinimg.com/564x/5d/89/36/5d89360f4091a4057ea5bf1ebbbc9b61.jpg",
            photo2: "https://i.pinimg.com/564x/7c/fc/4e/7cfc4ee91a83453b8b9e557a992827e3.jpg",
            photo3: "https://i.pinimg.com/564x/7d/6e/1b/7d6e1b51e250701b641b6d3c29fd42fb.jpg"
        },
    ]

    return (
        <>
            <div className="profylixPage">
                <div className="mainContent">
                    <div className="header">
                        <h1>profylix</h1>
                        <button onClick={(event) => handleClick(event, "#signup")}>sign up</button>
                    </div>
                    <div className="landing">
                        <img src="./profylixLogoWhite.png" alt="" />
                        <h2>WHO ARE YOU?</h2>
                        <h3>You may know the answer to that question... but do others?</h3>
                    </div>

                    <div className="intro">
                        <h2 className="sectionHeading">Introducing profylix</h2>

                        <div className="introInfo">
                            <div className="question">
                                <h3>What is it?</h3>
                                <h4>- A way to express yourself online</h4>
                                <h4>- It's a platform for creating customizable profiles</h4>
                                <h4>- Users can showcase their interests, hobbies, and achievements</h4>
                                <h4>- A space to connect with others and share your unique identity</h4>
                            </div>

                            <div className="question">
                                <h3>How does it work?</h3>
                                <h4>- Share your profile with others through customizable links</h4>
                                <h4>- Customize your profile with unique themes, layouts, and content</h4>
                                <h4>- Easily update and manage your profile to reflect your evolving interests and experiences</h4>
                                <h4>- Connect with others by exploring their profiles and engaging with shared content</h4>
                            </div>

                            <div className="question">
                                <h3>Why do you need it?</h3>
                                <h4>- Discover new opportunities and expand your network in a dynamic and interactive environment</h4>
                                <h4>- Showcase your creativity and individuality through personalized profiles that stand out</h4>
                                <h4>- Access valuable insights and resources to support your personal and professional growth</h4>
                            </div>

                            <div className="question">
                                <h3>What can you share?</h3>
                                <h4>- Share your interests, passions, and hobbies to showcase your personality</h4>
                                <h4>- Highlight your professional skills, experiences, and achievements to attract opportunities</h4>
                                <h4>- Share articles, blog posts, and other content that resonates with you and your audience</h4>
                                <h4>- Connect your social media accounts to seamlessly share updates and engage with your network</h4>
                            </div>

                        </div>
                    </div>

                    <div className="preview">
                        <h2 className="sectionHeading">Preview</h2>
                        <div className="previewMainContent">
                            {items.map((item, i) => {
                                return (
                                    <div key={item.name + i} className="previewItem">
                                            <div className="nameAndImg">
                                                <img src={item.img} alt="" />
                                                <div className="imgAndSocials">
                                                    <h2>{item.name}</h2>
                                                    <div className="line"></div>
                                                    <div className="socials">
                                                        <img src={item.socialImg1} alt="" />
                                                        <img src={item.socialImg2} alt="" />
                                                        <img src={item.socialImg3} alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="otherInfo">
                                                <h3>{item.description}</h3>
                                            </div>

                                            <div className="photos">
                                                <img src={item.photo1} alt="" />
                                                <img src={item.photo2} alt="" />
                                                <img src={item.photo3} alt="" />
                                            </div>


                                    </div>        
                                )
                            })}
                        </div>
                        <h6>The previews are a way to show the basic concept. These are not the finilized versions and the user will have much more customizable elements</h6>
                    </div>

                    <div className="signup" id="signup">
                        <h2>Sign Up</h2>

                        <form className="emailInput" ref={form} onSubmit={sendEmail}>
                            <input type="text" id="message" name="message" placeholder="email@gmail.com" />
                            <button id="sendButton" className="material-symbols-outlined" onClick={sendEmail}><span>send</span></button>
                        </form>

                        <h3>By signing up it shows that you are interested in this product. If enough people show interest I will make it. You will also have the opportunity to be the first to try out this product!</h3>
                    </div>
                </div>
            </div>
        </>
    );
}
