import img1 from './img1.png';

const tanjiro = {
    name: "Tanjiro",
    category: "Demon Slayer",
    image: img1,
    imageList: [img1],
    age: 15,
    gender: "Male",
    looks: {
        color: "Tan",
        height: 5.7, // in feet
        hairColor: "Black"
    },
    pronouns: ["he", "him", "his"],
    likes: ["Family", "Nature", "Training", "Kindness", "Nezuko"],
    dislikes: ["Demons", "Injustice", "Cruelty", "Suffering"],
    personality: ["Compassionate", "Determined", "Brave", "Empathetic", "Resilient"],
    intro: "I'm Tanjiro, and I'll protect my family and eradicate demons!"
};

export { tanjiro };
