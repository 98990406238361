import img1 from './img1.png';


const sasuke = {
    name: "Sasuke",
    category: "Naruto",
    image: img1,
    imageList: [img1],
    age: 17,
    gender: "male",
    looks: {
        color: "white",
        height: 5.9, // in feet
        hairColor: "black"
    },
    pronouns: ["he", "him", "his"],
    likes: ["training", "seeking power", "avenging his clan", "competitions", "quiet moments"],
    dislikes: ["Itachi Uchiha", "weakness", "failure", "people getting in his way", "losing control"],
    personality: ["determined", "intense", "independent", "stoic", "vengeful"],
    intro: "I walk my own path."
};

export { sasuke };
