import img1 from './img1.png';

const inosuke = {
    name: "Inosuke",
    category: "Demon Slayer",
    image: img1,
    imageList: [img1],
    age: 16,
    gender: "Male",
    looks: {
        color: "White",
        height: 5.7, // in feet
        hairColor: "Blue"
    },
    pronouns: ["he", "him", "his"],
    likes: ["Fighting", "Competition", "Adventure", "Freedom"],
    dislikes: ["Weakness", "Authority", "Being told what to do"],
    personality: ["Wild", "Aggressive", "Adventurous", "Loyal", "Brave"],
    intro: "I'm Inosuke! The boar-headed demon slayer who lives for battle and adventure!"
};

export { inosuke };
