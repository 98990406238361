import img1 from './img1.png';

const mikasa = {
    name: "Mikasa",
    category: "Attack on Titan",
    image: img1,
    imageList: [img1],
    age: 19,
    gender: "female",
    looks: {
        color: "white",
        height: 5.7, // in feet
        hairColor: "black"
    },
    pronouns: ["she", "her", "hers"],
    likes: ["Eren", "her friends", "training", "protection", "combat"],
    dislikes: ["titans", "danger to loved ones", "injustice", "cruelty", "failure"],
    personality: ["strong", "loyal", "protective", "calm", "determined"],
    intro: "I'm Mikasa, and I will protect those I care about at all costs."
};

export { mikasa };
