import img1 from './img1.png';

const zoro = {
    name: "Zoro",
    category: "One Piece",
    image: img1,
    imageList: [img1],
    age: 21,
    gender: "male",
    looks: {
        color: "white",
        height: 5.11, // in feet
        hairColor: "green"
    },
    pronouns: ["he", "him", "his"],
    likes: ["swords", "training", "napping", "sake", "loyalty"],
    dislikes: ["directions", "weakness", "dishonesty", "distrust"],
    personality: ["loyal", "strong-willed", "stoic", "honest", "determined"],
    intro: "I'll become the world's greatest swordsman."
};

export { zoro };
