import _ from 'lodash';


export class SnakeGame {

    constructor() {
        this.isInitialized = false;
        this.table = null;
        this.settingsButton = null;

        this.interval = null;

        this.x = 4;
        this.y = 4;
        this.direction = "right";
        this.score = 0;
        this.highScore = 0;
        this.snakeBody = [];
        this.currentFood = 0;
        this.currentBorder = 0;
        this.colorClicks = 1;
        this.speedDisplay = 10;
        this.settingsOpened = 1;
        this.snakeColorClass = "snake-color-green";
        this.cellColorCount = 0;

        this.MAX_FOOD = 3;
        this.MAX_BORDER = 5;
        this.SPEED = 200;
        this.SAFE_DISTANCE = 5;
        this.MAX_ATTERATIONS = 100; 
    }

    
    createTable (rows, collums) {
        for (let i = 0; i < rows; i++) {
            const tr = document.createElement('tr'); 

            for (let j = 0; j < collums; j++) {
                const td = document.createElement('td');
                if (this.cellColorCount % 2 === 0) {
                    td.setAttribute("class", "background-color-1");
                } else {
                    td.setAttribute("class", "background-color-2");
                }
                tr.appendChild(td);
                this.cellColorCount += 1;
            }

            this.cellColorCount += 1;
        
            if (this.table) this.table.appendChild(tr);
        }
    }


    gameOver () {
        const mainTable = document.getElementById("mainTable");
        if (mainTable !== null) {
            mainTable.remove();
        }
        

        const end_image = document.getElementById("end_image");
        end_image.classList.remove("hide")
        clearInterval(this.interval);

        

        const docHigh = document.getElementById("high_score");
        if (this.score > this.highScore) {
            docHigh.innerHTML = `High Score: ${this.score}`;
            this.highScore = this.score;
        }

    }


    spawnObstacles (tableSize) {
        let count = 0;
        while (this.currentBorder < this.MAX_BORDER) {
            count += 1;
            if (count > this.MAX_ATTERATIONS) break;

            let randomX = _.random(2, tableSize - 2);
            let randomY = _.random(2, tableSize - 2);
            const foodCell = this.table.rows[randomY].cells[randomX];

            if (foodCell.classList.contains("food") 
                || foodCell.classList.contains(this.snakeColorClass) 
                || foodCell.classList.contains("border")) {
                    continue;
                } 
            
            if (Math.abs(this.x - randomX) < this.SAFE_DISTANCE && Math.abs(this.y - randomY) < this.SAFE_DISTANCE){
                continue;
            }
        


            foodCell.classList.add('border');
            this.currentBorder += 1;
        }           
    }


    spawnFood (tableSize) {
        let count = 0;
        while (this.currentFood < this.MAX_FOOD) {
            count += 1;
            if (count > this.MAX_ATTERATIONS) break;

            let randomX = _.random(2, tableSize - 2);
            let randomY = _.random(2, tableSize - 2);
            const foodCell = this.table.rows[randomY].cells[randomX];
            if (foodCell.classList.contains("food") 
                || foodCell.classList.contains(this.snakeColorClass) 
                || foodCell.classList.contains("border")) {
                    continue;
                } 
            foodCell.classList.add('food');
            this.currentFood += 1;
        }     
    }


    setScore () {
        this.score += 1;
        const element = document.getElementById("score_display");
        element.innerHTML = `Score: ${this.score}`;
    }


    spawnSnake() {
        const cell = this.table.rows[this.y].cells[this.x];
        cell.classList.add(this.snakeColorClass); 
        this.snakeBody.push(cell);       
    }


    tick (tableSize) {  
        switch (this.direction) {
            case "up":
                this.y -= 1;
                break
            case "right":
                this.x += 1;
                break
            case "down":
                this.y += 1;
                break
            case "left":
                this.x -= 1  
                break 
            default:
                break   
        }
        
        const nextCell = this.table.rows[this.y].cells[this.x];
        if (nextCell.classList.contains("food")){
            nextCell.classList.remove('food');
            nextCell.classList.add(this.snakeColorClass);
            this.snakeBody.push(nextCell);
            this.setScore();
            this.currentFood -= 1;
            this.spawnFood(tableSize);
        } else if (nextCell.classList.contains("border")) {
            return this.gameOver();
        } else if (nextCell.classList.contains(this.snakeColorClass)) {
            return this.gameOver();
        } else {
            nextCell.classList.add(this.snakeColorClass);
            this.snakeBody.push(nextCell);
            const lastCell = this.snakeBody.shift();
            lastCell.classList.remove(this.snakeColorClass);
        }
    }


    createBorder (tableSize) {
        let borderX = 0;
        let borderY = 0;

        for (let i = 0; i < tableSize; i++) {
            this.table.rows[0].cells[borderX].classList.add('border');
            borderX += 1;
        }

        borderX = 0;

        for (let i = 0; i < tableSize; i++) {
            this.table.rows[tableSize - 1].cells[borderX].classList.add('border');
            borderX += 1;
        }

        for (let i = 0; i < tableSize; i++) {
            this.table.rows[borderY].cells[0].classList.add('border');
            borderY += 1;
        }
        
        borderY = 0;

        for (let i = 0; i < tableSize; i++) {
            this.table.rows[borderY].cells[tableSize - 1].classList.add('border');
            borderY += 1;
        }


    }


    deleteTable() {
        this.table.remove();


        const newTable = document.createElement("table");
        const mainDiv = document.getElementById("main");
        // new_table.setAttribute("id", "mainTable")
        newTable.setAttribute("id", "mainTable")

        mainDiv.appendChild(newTable);
        clearInterval(this.interval);

        this.table = newTable;

        this.x = 4;
        this.y = 4;
        this.direction = "right";
        this.interval = null;
        this.score = 0;
        this.snakeBody = [];
        this.currentFood = 0;
        this.currentBorder = 0;

        const end_image = document.getElementById("end_image");
        end_image.classList.toggle("hide")
    }


    loadGame() {
        this.score = 0;
        if (this.startImage !== null){
            this.startImage.remove();
        }

        this.score = 0;
        const tableSize = 20;
        this.createTable(tableSize, tableSize);
        this.createBorder(tableSize);
        this.spawnObstacles(tableSize);
        this.spawnFood(tableSize);
        this.spawnSnake();
        this.interval = setInterval(() => {
            this.tick(tableSize);
        }, this.SPEED)

    }


    colorChange() {
        this.colorClicks += 1;
        this.snakeColorClasses = ["snake-color-green", "snake-color-red", "snake-color-blue"];

        let currentClass = this.snakeColorClass;
        let newClass = this.snakeColorClasses[this.colorClicks % this.snakeColorClasses.length];

        for (const snakeCell of this.snakeBody) {
            snakeCell.classList.remove(currentClass);
            snakeCell.classList.add(newClass);
        }  

        this.snakeColorClass = newClass;
        const changeColor = document.getElementById("changeColor")
        changeColor.setAttribute("class", this.snakeColorClass)

    }


    gameRestart () {
        // const gameOverImage = document.getElementById("game_over");
        // if (gameOverImage !== null){
        //     gameOverImage.remove();
        // }

        

        this.deleteTable();
        this.loadGame();
        this.score = 0;
        const score_display = document.getElementById("score_display")
        score_display.innerHTML = `Score: ${this.score}`;

        const end_image = document.getElementById("end_image");
        end_image.classList.add("hide")
        

    }

    
    bindEvents() {
        this.table = document.getElementById('mainTable');
        this.settingsButton = document.getElementById("settingsButton");
        this.startImage = document.getElementById("start_image");
        const restartButton = document.getElementById("restart");
        this.changeColorButton = document.getElementById("changeColor");
        const settingsCloseButton =  document.getElementById("closeButton");

        this.startImage.addEventListener("click", () => this.loadGame());
        restartButton.addEventListener("click", () => this.gameRestart());
        this.changeColorButton.addEventListener("click",() =>  this.colorChange())
        this.settingsButton.addEventListener("click", () => this.onSettingsClick());
        settingsCloseButton.addEventListener("click", () => this.onSettingsClick());

        window.addEventListener('keydown', (event) =>  {
            if (event.key === "w" || event.key === "ArrowUp" || event.key === "W") {
                if (this.direction !== "down") this.direction = "up";
            } else if (event.key === "d" || event.key === "ArrowRight" || event.key === "D") {
                if (this.direction !== "left") this.direction = "right";
            } else if (event.key === "s" || event.key === "ArrowDown" || event.key === "S") {
                if (this.direction !== "up") this.direction = "down";
            } else if (event.key === "a" || event.key === "ArrowLeft" || event.key === "A") {
                if (this.direction !== "right") this.direction = "left"
            }
        });
    
        window.addEventListener('keydown', (event) => {
            if (event.key === "r" || event.key === "R") this.gameRestart();   
        });
    
        window.addEventListener('keydown', (event) => {
            if (event.key === "c" || event.key === "C") this.colorChange();
        });  

        window.addEventListener("keydown", function(e) {
            if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
                e.preventDefault();
            }
        }, false);
    }


    onSettingsClick() {
        const snakeSettings = document.getElementById("snakeSettings");
        if (!snakeSettings) return alert("Setings dialog not found");
            
        if (!snakeSettings.classList.contains('hide')) {
            snakeSettings.classList.toggle("hide");
            return;
        }

        snakeSettings.classList.remove("hide");
        
        this.settingsOpened += 1;

        const minspeed = document.getElementById("minspeed");
        const maxspeed = document.getElementById("maxspeed");
        const minfood = document.getElementById("minfood");
        const maxfood = document.getElementById("maxfood");
        const minblock = document.getElementById("minblock");
        const maxblock = document.getElementById("maxblock");

        this.updateSettings();


        minspeed.addEventListener("click", () => {
            if (this.speedDisplay > 1) {
                this.SPEED += 20;
                this.speedDisplay -= 1;
                this.updateSettings()
            }
        })
        
        maxspeed.addEventListener("click", () => {
            if (this.speedDisplay < 15){
                this.SPEED -= 20;
                this.speedDisplay += 1;
                this.updateSettings()
            } 
        })
        
        minfood.addEventListener("click", () => {
            if (this.MAX_FOOD > 1){
                this.MAX_FOOD -= 1;
                this.updateSettings()
                
            }
        })
        
        maxfood.addEventListener("click", () => {
            if (this.MAX_FOOD < 50) {
                this.MAX_FOOD += 1;
                this.updateSettings()
            }
        })
        
        minblock.addEventListener("click", () => {
            if (this.MAX_BORDER > 1){
                this.MAX_BORDER -= 1;
                this.updateSettings()
            }
        })
        
        maxblock.addEventListener("click", () => {
            if (this.MAX_BORDER < 50) {
                this.MAX_BORDER += 1;
                this.updateSettings()
            }
        })
        // scroll(0, 1000);

        snakeSettings.scrollIntoView({behavior: "smooth"})
    }
    

    updateSettings() {
        const speednum = document.getElementById("speednum");
        const foodnum = document.getElementById("foodnum");
        const blocknum = document.getElementById("blocknum");

        if (speednum) speednum.innerHTML = this.speedDisplay.toString();
        if (foodnum) foodnum.innerHTML = this.MAX_FOOD.toString();
        if (blocknum) blocknum.innerHTML = this.MAX_BORDER.toString();
    }

    startGame() {
        if (this.isInitialized) return;
        else this.isInitialized = true;
        
        this.bindEvents()
    }

}


