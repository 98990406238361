import img1 from './img1.png';

const robin = {
    name: "Robin",
    category: "One Piece",
    image: img1,
    imageList: [img1],
    age: 30,
    gender: "female",
    looks: {
        color: "white",
        height: 6.0, // in feet
        hairColor: "black"
    },
    pronouns: ["she", "her", "her"],
    likes: ["history", "reading", "archeology", "knowledge", "flower viewing"],
    dislikes: ["the World Government", "injustice", "people who harm the innocent", "ignorance"],
    personality: ["calm", "intelligent", "analytical", "mysterious", "resilient"],
    intro: "I seek the true history of the world."
};

export { robin };
