import React from "react";
import "./mobileblock.css"

export default function MobileBlock() {
  return (
    <>
      <div className="mobileBlockPage">
        <h1>Sorry this page isn't available on mobile. Please open this page on a computer.</h1>
        <a href="/games">BACK</a>
      </div> 
    </>
  );
}