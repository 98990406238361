import img1 from './img1.png';

const zenitsu = {
    name: "Zenitsu",
    category: "Demon Slayer",
    image: img1,
    imageList: [img1],
    age: 16,
    gender: "Male",
    looks: {
        color: "White",
        height: 5.4, // in feet
        hairColor: "Yellow"
    },
    pronouns: ["he", "him", "his"],
    likes: ["Sleeping", "Cats", "Beautiful girls", "Peace and quiet"],
    dislikes: ["Demons", "Danger", "Conflict", "Being awake"],
    personality: ["Cowardly", "Loyal", "Passionate", "Sensitive", "Brave (when unconscious)"],
    intro: "I'm Zenitsu! The most cowardly and unlucky demon slayer..."
};

export { zenitsu };
