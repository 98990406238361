import React from "react";
import Header from "../Header/Header.jsx";

import "./register.css"
import "./register_js.js"

export default function Register() {
    return (
        <>
            <Header />  

            <div className="registerPage">
                <div className="formBox">
                    <form action="/">
                        <h2><a href="account">REGISTER</a></h2>

                        <div className="inputbox">
                            <ion-icon name="happy-outline"></ion-icon>
                            <input type="text" required />
                            <label for="">Name</label>
                        </div>

                        <div className="inputbox">
                            <ion-icon name="mail-outline"></ion-icon>
                            <input type="email" value="" required />
                            <label for="">Email</label>
                        </div>

                        <div className="inputbox">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                            <input type="text" required />
                            <label for="">Password</label>
                        </div>

                        <div className="inputbox">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                            <input type="text" required />
                            <label for="">Confirm Password</label>
                        </div>
                                        
                        <button className="mainBtn" type="submit">REGISTER</button>
                        
                        <div className="line"></div>

                        <a href="login" >
                            <h6 className="loginBtn">LOG IN</h6>    
                        </a>
                        
                    </form>
                </div>
                <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
                <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
            </div>
        </>
    )
}



