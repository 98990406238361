import React from "react";
import Header from "../Header/Header.jsx";

import "./signin.css";
import "./check.js"

export default function SignIn() {
    return (
        <>
            <Header />

            <div className="loginPage">
                <div className="formBox">
                        <form action="/">
                            <h2><a href="/account">LOGIN</a></h2>
                            <div className="inputbox">
                                <ion-icon name="mail-outline"></ion-icon>
                                <input type="email" value="" required />
                                <label for="">Email</label>
                            </div>
                            <div className="inputbox">
                                <ion-icon name="lock-closed-outline"></ion-icon>
                                <input type="password" id="passwordInput" required />
                                <label for="">Password</label>
                            </div>
                            <div className="forget">
                                <label for="">
                                    <div className="showBox">
                                        <ion-icon className="showPassword" name="eye-off-outline"></ion-icon>
                                        <b><h3>Show Password</h3></b> 
                                    </div>
                                    <b><a href="/forgot-password">Forgot Password</a></b>
                                </label>
                            </div>
                            
                            <button className="mainBtn" type="submit">LOG IN</button>
                            
                            
                            <div className="register">
                                <p>OR <a href="/register">REGISTER</a></p>
                            </div>
                        </form>
                </div>
            </div>
            {/* <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
            <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>     */}
        </>
    )
}
