export  class MiniHeader {

    constructor() {
        this.isInitialized = false;
    }

    bindEvents() {
        let bottomHeader = document.getElementById("bottomHeader")
        let menuHeaderButton = document.getElementById("menuHeaderButton")

        menuHeaderButton.addEventListener("click", () => {
            bottomHeader.classList.toggle("headerOpen")
        })
    }

    startGame() {
        if (this.isInitialized) return;
        else this.isInitialized = true;
        
        this.bindEvents()
    }

}