import img1 from './img1.png';

const hinata = {
    name: "Hinata",
    category: "Naruto",
    image: img1,
    imageList: [img1],
    age: 17,
    gender: "Female",
    looks: {
        color: "White",
        height: 5.3, // in feet
        hairColor: "Blue-Black"
    },
    pronouns: ["she", "her", "hers"],
    likes: ["Naruto", "Training", "Her family", "Gardening"],
    dislikes: ["Conflict", "Hurting others", "Failure"],
    personality: ["Shy", "Kind", "Determined", "Loyal", "Gentle"],
    intro: "I'm Hinata Hyuga, determined to prove my worth and protect those I love."
};

export { hinata };
