import jumpMp3 from './sound/jumping.mp3'
import deathMp3 from './sound/death.mp3'
import portalMp3 from './sound/portal.mp3'
import backgroundMp3 from './sound/background.mp3'
import {gravity, levelsList, baseSpeed, platformSpeed, jumpStrength, gameColors} from "./configurations";

export class ImmortalCubeGame {

    init() {
        if (this.isInitialized) return;
        else this.isInitialized = true;

        // === WORLD ===
        this.canvas = document.getElementById("canvas");
        this.ctx = this.canvas.getContext("2d");
        this.width = window.innerWidth 
        this.height = window.innerHeight;
        this.canvas.width = this.width
        this.canvas.height = this.height;
        this.xScreenRatio = 10;
        this.yScreenRatio = 16;
        this.blockWidth = this.width / this.xScreenRatio;
        this.blockHeight = this.height / this.yScreenRatio;
        this.spawnPointX = 20;
        this.spawnPointY = 100;

        // === SOUND ===
        this.jumpSound = new Audio(jumpMp3);
        this.backgroundSound = new Audio(backgroundMp3)
        this.soundEffects = true;
        this.music = true;

        // === GAME CHANGE ===
        this.gravity = gravity;
        this.jumpStrength = jumpStrength;
        this.baseSpeed = baseSpeed + (this.blockWidth * 0.04);
        this.platformSpeed = platformSpeed;
        this.gameColors = gameColors;
        this.world = [];
        this.levelsList = levelsList;

        // === EXTRA ===
        this.playerX = this.spawnPointX;
        this.playerY = this.spawnPointY;
        this.playerWidth = 50;
        this.playerHeight = 50;
        this.jumpLock = true;
        this.currentSpeed = 0;
        this.currentLevelNumber = 0;
        this.velocity = 0;
        this.end = false;

        this.bindEvents()
    }

    drawLevelText() {
        this.ctx.font = "60px Nunito";
        this.ctx.fillStyle = "white"
        if (this.currentLevelNumber + 1 >= 10) {
            this.ctx.fillText("LEVEL " + (this.currentLevelNumber + 1), this.width - 290, 70);
        } else {
            this.ctx.fillText("LEVEL " + (this.currentLevelNumber + 1), this.width - 250, 70);
        }
    }

 
    clearLevel() {
        this.world = []
        this.drawWorld()
    }

    playBackgroundMusic() {
        if (this.music && !this.end) this.backgroundSound.play()
    }

    
    createLevels() {
        this.currentLevel = this.levelsList[this.currentLevelNumber]

        for (let floor = 0; floor < this.currentLevel.length; floor++) {
            for (let i = 0; i <= this.currentLevel[floor].length; i++) {
                if (this.currentLevel[floor][i] === "#") {
                    this.world.push({
                        name: "floor",
                        movable: false,
                        color: this.gameColors.walls,
                        x: i * this.blockWidth,
                        y: this.height - (this.blockHeight * (this.yScreenRatio - floor)),
                        width: this.blockWidth + 1,
                        height: this.blockHeight + 1
                    })
                } else if (this.currentLevel[floor][i] === "O") {
                    this.world.push({
                        name: "portal",
                        movable: false,
                        color: this.gameColors.portal,
                        x: i * this.blockWidth + (this.blockWidth / 4),
                        y: this.height - (this.blockHeight * (this.yScreenRatio - floor)),
                        width: this.blockWidth + 1 - (this.blockWidth / 2),
                        height: this.blockHeight * 2 + 1
                    })
                } else if (this.currentLevel[floor][i] === "=") {
                    this.world.push({
                        name: "moving",
                        movable: false,
                        color: this.gameColors.moving,
                        x: i * this.blockWidth,
                        y: this.height - (this.blockHeight * (this.yScreenRatio - floor)),
                        width: this.blockWidth * 2 + 1,
                        height: this.blockHeight + 1,
                        direction: "right"
                    })
                } else if (this.currentLevel[floor][i] === "!") {
                    this.world.push({
                        name: "spike",
                        movable: false,
                        color: this.gameColors.spike,
                        x: i * this.blockWidth,
                        y: this.height - (this.blockHeight * (this.yScreenRatio - floor)),
                        width: this.blockWidth + 1,
                        height: this.blockHeight + 1,
                        maxRight: null,
                        maxLeft: null,
                    })
                }
            
            }
        }

        this.findCordsOfPlatform()
    }


    findCordsOfPlatform() {
        let blocksList = []
        for (let i = 0; i < this.world.length; i++) {
            if (this.world[i].name === "moving") {
                for (let j = 0; j < this.world.length; j++) {
                    if (this.world[i].y === this.world[j].y && this.world[j].name !== "moving") {
                        blocksList.push(this.world[j])
                    }
                }

                blocksList.sort(function(a, b){return a-b});
                let maxLeft;
                let maxRight;

                if (blocksList === []) {
                    maxLeft = 0;
                    maxRight = this.width;
                } else if (blocksList.length === 1) {
                    if (this.world[i].x > blocksList[0].x) {
                        maxLeft = blocksList[0].x + blocksList[0].width;
                        maxRight = this.width;
                    } else {
                        maxLeft = 0;
                        maxRight = blocksList[0].x;
                    }
                } else {
                    let lock = false;
                    for (let u = 0; u < blocksList.length; u++) {
                        if (!lock) {
                            if (this.world[i].x < blocksList[u].x) {
                                if (u === 0) {
                                    maxLeft = 0
                                    maxRight = blocksList[u].x;

                                } else if (u === blocksList.length) {
                                    maxLeft = blocksList[u - 1].x + blocksList[u - 1].width;
                                    maxRight = this.width
                                } else {
                                    maxLeft = blocksList[u - 1].x + blocksList[u - 1].width;
                                    maxRight = blocksList[u].x;
                                }
                                lock = true;
                            }
                        }
                    }
                }

                this.world[i].maxLeft = maxLeft
                this.world[i].maxRight = maxRight;
            }
        }
    
    }


    drawLava() {
        this.ctx.fillStyle = this.gameColors.lava
        this.ctx.fillRect(0, this.height - this.blockHeight, this.width, this.blockHeight);
    }


    checkDeath() {
        if (this.playerY + this.playerHeight >= this.height - this.blockHeight) this.restart(false)
    }


    drawPlayer() {
        this.ctx.fillStyle = this.gameColors.player
        this.ctx.fillRect(this.playerX, this.playerY, this.playerWidth, this.playerHeight);
    }


    clearBackground() {
        this.ctx.fillStyle = this.gameColors.background
        this.ctx.fillRect(0, 0, this.width, this.height);
    }


    movePlatforms() {
        for (let i = 0; i < this.world.length; i++) {
            if (this.world[i].name === "moving") {
                if (this.world[i].direction === "right") {
                    this.world[i].x += this.platformSpeed;
                } else {
                    this.world[i].x -= this.platformSpeed;
                }

                if (this.world[i].x + this.world[i].width > this.width) {
                    this.world[i].x -= this.platformSpeed;
                    this.world[i].direction = "left"
                }

                if (this.world[i].x < 0) {
                    this.world[i].x += this.platformSpeed;
                    this.world[i].direction = "right"
                }

                if (this.world[i].maxLeft) {
                    if (this.world[i].x < this.world[i].maxLeft) {
                        this.world[i].x += this.platformSpeed;
                        this.world[i].direction = "right"
                    }
                }

                if (this.world[i].maxRight) {
                    if (this.world[i].x + this.world[i].width > this.world[i].maxRight) {
                        this.world[i].x -= this.platformSpeed;
                        this.world[i].direction = "left"
                    }
                }
            }
        }
    }


    drawWorld() {  
        for (let i = 0; i < this.world.length; i++) {
            this.ctx.fillStyle = this.world[i].color;
            if (this.world[i].name === "spike") {
                this.ctx.beginPath()
                this.ctx.moveTo(this.world[i].x + (this.blockWidth / 2), this.world[i].y + 5)
                this.ctx.lineTo(this.world[i].x + this.blockWidth, this.world[i].y + this.blockHeight)
                this.ctx.lineTo(this.world[i].x, this.world[i].y + this.blockHeight)
                this.ctx.lineTo(this.world[i].x + (this.blockWidth / 2), this.world[i].y + 5)
                this.ctx.strokeStyle = this.world[i].color;
                this.ctx.stroke()
                this.ctx.closePath()
                this.ctx.fill()
            } else {
                this.ctx.fillRect(this.world[i].x, this.world[i].y, this.world[i].width, this.world[i].height);
            }
        }
    }


    gameOver() {
        this.backgroundSound.pause()

        console.log("gaqmeover")
        const gameOverScreen = document.getElementById("gameOver");
        const settingsButton = document.getElementById("settingsButton");
        gameOverScreen.classList.remove("hide")
        this.canvas.classList.add("hide")
        settingsButton.classList.add("hide")
        this.end = true;
    }


    nextLevel(skip) {
        if (this.soundEffects && !this.end) new Audio(portalMp3).play();

        if (skip) {
            if (this.currentLevelNumber + 1 === this.levelsList.length) return this.gameOver()

            this.currentLevelNumber += 1;
            this.restart(true)
            this.clearLevel()
            this.createLevels()

        } else {
            // If user presses the skip button
            this.currentLevelNumber = this.levelsList.length - 1;
            this.restart(true)
            this.clearLevel()
            this.createLevels()
        }
    } 

    isPointCollided(obj, point) {
        const pointX = point[0];
        const pointY = point[1];
      
        const objLeft =  obj.x;
        const objRight = obj.x + obj.width;
        const objTop = obj.y;
        const objBottom = obj.y + obj.height;

        if ((pointX >= objLeft && pointX <= objRight) && (pointY >= objTop && pointY <= objBottom)) {
            return obj;
        } else {
            return false;
        }
    }

    newFixPosition(collidedPoints) {
        // Collision
        if (collidedPoints.topLeft && collidedPoints.topRight && collidedPoints.bottomRight) {
            // Top right corner
            this.playerY = collidedPoints.topLeft.y + collidedPoints.topLeft.height + 1;
            this.playerX = collidedPoints.topRight.x - this.playerWidth - 1;
            this.velocity = 0;
            return "top"
        }

        if (collidedPoints.topLeft && collidedPoints.topRight && collidedPoints.bottomLeft) {
            // Top left corner
            this.playerY = collidedPoints.topLeft.y + collidedPoints.topLeft.height + 1;
            this.playerX = collidedPoints.topLeft.x + collidedPoints.topLeft.width + 1;
            this.velocity = 0;
            return "top"
        }

        if (collidedPoints.topLeft && collidedPoints.bottomRight && collidedPoints.bottomLeft) {
            // bottom left corner
            this.playerY = collidedPoints.bottomRight.y  - this.playerHeight;
            this.playerX = collidedPoints.topLeft.x + collidedPoints.topLeft.width + 1;
            this.velocity = 0;

            return "top"

        }

        if (collidedPoints.topRight && collidedPoints.bottomRight && collidedPoints.bottomLeft) {
            // bottom right corner
            this.playerY = collidedPoints.bottomLeft.y - this.playerHeight;
            this.playerX = collidedPoints.topRight.x - this.playerWidth - 1;
            this.velocity = 0;

            return "top"
        }


        if (collidedPoints.topLeft && collidedPoints.topRight) {
            // TOP LINE
            this.playerY = collidedPoints.topLeft.y + collidedPoints.topLeft.height + 1;
            this.velocity = 0;
            return "top"
        }

        if (collidedPoints.topLeft && collidedPoints.bottomLeft) {
            // LEFT LINE
            this.playerX = collidedPoints.topLeft.x + collidedPoints.topLeft.width + 1;
            return "left"
        }

        if (collidedPoints.topRight && collidedPoints.bottomRight) {
            // Right line
            this.playerX = collidedPoints.topRight.x - this.playerWidth - 1;
            return "right"
        }

        if (collidedPoints.bottomLeft && collidedPoints.bottomRight) {
            // BottomLine
            this.playerY = collidedPoints.bottomLeft.y - this.playerHeight;
            this.velocity = 0;

            return "bottom"
        }

        if (collidedPoints.bottomRight) {
        //     if (
        //         (this.playerX + this.playerWidth > collidedPoints.bottomRight.x && this.playerX + this.playerWidth < collidedPoints.bottomRight.x + 10)
        //         && !(this.playerY + this.playerHeight > collidedPoints.bottomRight.y && this.playerY + this.playerHeight < collidedPoints.bottomRight.y + 10)
        //     ) {
        //         this.playerX = collidedPoints.topRight.x - this.playerWidth - 1;
        //     } else {}

            this.playerY = collidedPoints.bottomRight.y - this.playerHeight;
            this.velocity = 0;

            return "bottom"
        }

        if (collidedPoints.bottomLeft) {
            this.playerY = collidedPoints.bottomLeft.y - this.playerHeight;
            this.velocity = 0;
            return "bottom"
        }

        if (collidedPoints.topLeft) {
            this.playerY = collidedPoints.topLeft.y + collidedPoints.topLeft.height + 1;
            this.velocity = 0;
            return "top"
        }

        if (collidedPoints.topRight) {
            this.playerY = collidedPoints.topRight.y + collidedPoints.topRight.height + 1;
            this.velocity = 0;
            return "top"
        }

        return null
    }


    checkCollision() {
        const collidedPoints = {
            topLeft: false,
            topRight: false,
            bottomLeft: false,
            bottomRight: false
        };

        const topLeft = [this.playerX, this.playerY];
        const topRight = [this.playerX + this.playerWidth, this.playerY];
        const bottomLeft = [this.playerX, this.playerY + this.playerHeight];
        const bottomRight = [this.playerX + this.playerWidth, this.playerY + this.playerHeight];

        // Checks if each point is colliding with an object
        for (let i = 0; i < this.world.length; i++) {
            let obj = this.world[i];

            collidedPoints.topLeft = collidedPoints.topLeft || this.isPointCollided(obj, topLeft);
            collidedPoints.topRight = collidedPoints.topRight || this.isPointCollided(obj, topRight);
            collidedPoints.bottomLeft = collidedPoints.bottomLeft || this.isPointCollided(obj, bottomLeft);
            collidedPoints.bottomRight = collidedPoints.bottomRight || this.isPointCollided(obj, bottomRight);
        }

        // Uses information about found points to adjust the position
        this.newFixPosition(collidedPoints);

        // Checks if the points collided with something important
        if (collidedPoints.topLeft?.name === "portal" ||
            collidedPoints.topRight?.name === "portal" ||
            collidedPoints.bottomLeft?.name === "portal" ||
            collidedPoints.bottomRight?.name === "portal") {
            return this.nextLevel(true);
        }

        if (collidedPoints.topLeft?.name === "spike" ||
            collidedPoints.topRight?.name === "spike" ||
            collidedPoints.bottomLeft?.name === "spike" ||
            collidedPoints.bottomRight?.name === "spike") {
            return this.restart(false);
        }

        if (collidedPoints.topLeft?.name === "moving" ||
            collidedPoints.topRight?.name === "moving" ||
            collidedPoints.bottomLeft?.name === "moving" ||
            collidedPoints.bottomRight?.name === "moving") {
            if (collidedPoints.bottomRight?.name === "moving" || collidedPoints.bottomLeft?.name === "moving") {
                if (collidedPoints.bottomRight?.direction === "right" || collidedPoints.bottomLeft?.direction === "right") {
                    this.playerX += this.platformSpeed;
                } else {
                    this.playerX -= this.platformSpeed;
                }
            }
        }

        if ((!collidedPoints.topLeft && !collidedPoints.topRight) && (collidedPoints.bottomLeft || collidedPoints.bottomRight)) {
            this.jumpLock = false;
        } else {
            this.velocity += this.gravity;
        }

    }


    touchingBorder() {
        if (this.playerX <= 0) {
            this.playerX = 0;
        }

        if (this.playerX + this.playerWidth > this.width) {
            this.playerX = this.width - this.playerWidth;
        }
    }


    updatePlayer() {
        this.playerX += this.currentSpeed;
        this.playerY += this.velocity;
    }


    playerJump() {
        if (!this.jumpLock && this.velocity <= 0.5) {
            if(this.soundEffects) this.jumpSound.play();
            this.velocity = -this.jumpStrength;
            this.jumpLock = true;
        }
    }


    moveRight() {
        this.currentSpeed = this.baseSpeed;
    }


    moveLeft() {
        this.currentSpeed = -this.baseSpeed;
    }


    stopMovement() {
        this.currentSpeed = 0;
    }


    restart(nextLevel) {
        this.playerX = this.spawnPointX;
        this.playerY = this.spawnPointY;
        this.jumpLock = true;
        this.velocity = 0;

        if (!nextLevel && this.soundEffects && !this.end) new Audio(deathMp3).play();

        if (this.end) {
            const gameOverScreen = document.getElementById("gameOver");
            const settingsButton = document.getElementById("settingsButton");
            gameOverScreen.classList.add("hide")
            this.canvas.classList.remove("hide")
            settingsButton.classList.remove("hide")
            this.end = false;
            this.currentLevelNumber = 0;
            this.clearLevel()
            this.createLevels()
            this.drawWorld()
        }
    }

    bindEvents() {
        const startButton = document.getElementById("startButton");
        const startScreen = document.getElementById("startScreen");
        const settingsButton = document.getElementById("settingsButton");
        const musicSetting = document.getElementById("musicSetting");
        const soundEfectSetting = document.getElementById("soundEfectSetting");
        const closeButton = document.getElementById("closeButton");
        const skipLevelButton = document.getElementById("skipLevelButton");
        const musicOn = document.getElementById("musicOn");
        const musicOff = document.getElementById("musicOff");
        const soundEfectOn = document.getElementById("soundEfectOn");
        const soundEfectOff = document.getElementById("soundEfectOff");



        document.addEventListener("keypress", (e) => {
            if (e.key === "r" || e.key === "R") this.restart()

            if (this.end) return
            if (e.key === "w" || e.key === "W") this.playerJump()
            if (e.key === "a" || e.key === "A") this.moveLeft()
            if (e.key === "d" || e.key === "D") this.moveRight()
        })

        document.addEventListener("keyup", (e) => {
            if (e.key === "a" || e.key === "A" || e.key === "d" || e.key === "D") this.stopMovement()
        })

        startButton.addEventListener("click", () => {
            startScreen.classList.add("hide")
            this.canvas.classList.remove("hide")
            settingsButton.classList.remove("hide")
            this.startGame()
        })
    
        settingsButton.addEventListener("click", () => {
            this.openSettings()
        })
    
    
        skipLevelButton.addEventListener("click", () => {
            this.nextLevel(false)
            this.closeSettings()
        })

        
        closeButton.addEventListener("click", this.closeSettings)
        
        musicSetting.addEventListener("click", () => {
            
            if (this.music) {
                musicOn.classList.add("hide")
                musicOff.classList.remove("hide")
                this.backgroundSound.pause()
                this.music = false;
            } else {
                musicOn.classList.remove("hide")
                musicOff.classList.add("hide")
                this.backgroundSound.play()
                this.music = true;
            }
        }) 

        soundEfectSetting.addEventListener("click", () => {
            
            if (this.soundEffects) {
                soundEfectOn.classList.add("hide")
                soundEfectOff.classList.remove("hide")
                this.soundEffects = false;
            } else {
                soundEfectOn.classList.remove("hide")
                soundEfectOff.classList.add("hide")
                this.soundEffects = true;
            }
        }) 
    }


    gameLoop() {
        this.playBackgroundMusic()

        this.clearBackground()
        this.drawLava()
        this.updatePlayer()
        this.checkCollision()
        this.touchingBorder()
        this.checkDeath()
        this.movePlatforms()
        this.drawWorld()
        this.drawLevelText()
        this.drawPlayer() 
    }


    openSettings() {
        const settingsHolder = document.getElementById("settingsHolder");
        const settingsButton = document.getElementById("settingsButton");
        settingsHolder.classList.remove("hide")
        this.canvas.classList.add("hide")
        settingsButton.classList.add("hide")
    }

    closeSettings() {
        const settingsHolder = document.getElementById("settingsHolder");
        const settingsButton = document.getElementById("settingsButton");
        let canvas = document.getElementById("canvas");
        settingsHolder.classList.add("hide")
        canvas.classList.remove("hide")
        settingsButton.classList.remove("hide")
    }

    startGame() {
        this.createLevels()

        setInterval(() => {
            this.gameLoop()
        }, 16)
    }
}