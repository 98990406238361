import React from "react";
import Header from "../../Components/Header/Header.jsx";
import MobileBlock from "../Mobile/MobileBlock.jsx";

import "./tictactoe_styles.css";
import {TictactoeGame} from "./tictactoe_js.js";

const tictactoeGame = new TictactoeGame();

export default class Tictactoe extends React.Component {
    
    componentDidMount() {
        tictactoeGame.startGame()
    }
  
    render() {
        return (
            <>
                <MobileBlock />

                <div className="tictactoeGame">
                  <Header />

                    <div className="game">
                        <div className="score">
                            <h1>Tic Tac Toe</h1>
                            <div className="data">
                                <h2 id="xWin">X Wins: 0</h2>
                                <h2 id="oWin">O Wins: 0</h2>
                            </div>            
                        </div>
                        <div id="tableHolder"></div>
                        <table id="table" className="table"></table>
                        <p>Press R to restart</p>
                    </div>
                </div>

            </>
        )
    }
}