import React from "react";
import Header from "../Header/Header.jsx";

import "./about.css"
import Nikita from "../../Shared/images/nikita.jpg";
import Logo from "../../Shared/images/web_logo_white.png";
import Support from "../../Shared/images/support.jpg";

export default function About() {
  return (
    <>
      
        <div className="aboutPage">
            <Header />

            <div className="cardHolder">
                <img src={Logo} alt="shadowgames" />

                <div className="infoText">
                    <h2>ABOUT US</h2>
                    <p>Shadow Games is a website that offers a wide range of games spanning various genres. What's even better is that it's currently accessible in schools, so you can enjoy it without any restrictions. As time passes, you can look forward to more exciting games being added and improvements made to the existing ones. There are plenty of exciting plans in store for the future, so be sure to stay tuned for all the upcoming developments!</p>
                </div>
            </div>

            <div className="cardHolder"> 
                <div className="infoText">
                    <h2>ABOUT ME</h2>
                    <p>I'm Nikita Yarotskiy, a 17-year-old who loves programming. I'm learning to make websites, games, and I want to explore artificial intelligence in the future. I know Python, HTML, CSS, and JavaScript, among other things. I'm a high school student, so it can be tough to balance school work and coding, but I'm giving my best in both areas. I'm excited about what's coming next in the tech world, and I'm determined to keep learning and contributing to it.
                    </p>
                </div>
                <img src={Nikita} alt="Nikita Yarotskiy" />
            </div>

            <div className="supportPage">
                <h3>SUPPORT MY WORK</h3>
                <h4>☕ Buy me a french vanilla :)</h4>

                <form action="https://www.paypal.com/donate" method="post" target="_top" className="paypalButton">
                    <input type="hidden" name="hosted_button_id" value="PFAQKN3ZUG74C" />
                    <input type="image" className="paypalImage" src={Support} border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_CA/i/scr/pixel.gif" width="1" height="1" />
                </form>
            </div>

            <div className="contact">
                <div className="contactItems">
                    <h2>CONTACT US</h2>
                    <a href="mailto:nikeejsg@gmail.com">
                        <img src="https://mailmeteor.com/logos/assets/PNG/Gmail_Logo_512px.png" alt="" />
                    </a>
                </div>

                <div className="contactItems">
                    <h2>FIND US</h2>
                    <a href="https://www.instagram.com/shadowgames.ca/">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </>
  );
}

