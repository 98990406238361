import img1 from './img1.png';

const luffy = {
    name: "Luffy",
    category: "One Piece",
    image: img1,
    imageList: [img1],
    age: 19,
    gender: "male",
    looks: {
        color: "white",
        height: 5.8, // in feet
        hairColor: "black"
    },
    pronouns: ["he", "him", "his"],
    likes: ["adventure", "meat", "freedom", "making friends", "challenges"],
    dislikes: ["bitter foods", "tyrants", "people who harm his friends", "navy", "being restrained"],
    personality: ["carefree", "determined", "optimistic", "fearless", "charismatic"],
    intro: "I'm gonna be the Pirate King!"
};

export { luffy };
