// === GAME ADJUSTMENTS ===

export const gravity = 0.5;
export const jumpStrength = 20;
export const baseSpeed = 5;
export const platformSpeed = 5;

export const gameColors = {
    walls: "#393E46",
    background: "#929AAB",
    player: "#EEEEEE",
    lava: "#ED2B2A",
    portal: "#EEEEEE",
    moving: "#EEEEEE",
    spike: "#EEEEEE",
}

// === LEVEL ===
// #: Floor
// =: Moving platform
// !: Spike
// O: Portal

export const levelsList = [
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "---------O",
        "----------",
        "####--####",
        "####--####"
    ],
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "-----#----",
        "-----#----",
        "-----#---O",
        "-----#----",
        "##--##--##",
        "##--##--##"
    ],
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "---------O",
        "--!-!!-!--",
        "###########",
        "###########"
    ],
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "---------O",
        "----------",
        "--------##",
        "----!-#-##",
        "----#-#-##",
        "--#-#-#-##",
        "#-#-#-#-##",
        "#-#-#-#-##"
    ],
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "#--=---#--",
        "-------#--",
        "-------#--",
        "-------#--",
        "-------#--",
        "-------#--",
        "-------#-O",
        "-------#--",
        "-------###",
        "-------###"
    ],
    [
        "---#------",
        "---#------",
        "---#------",
        "---#------",
        "---#------",
        "---#------",
        "#--#------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "---------O",
        "----------",
        "--=------#",
        "---------#"
    ],
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "------!!--",
        "########--",
        "----------",
        "----------",
        "----------",
        "=--#######",
        "----------",
        "---------O",
        "----------",
        "##########",
        "##########"
    ],
    [
        "--#-------",
        "--#-------",
        "--#----!--",
        "--#----#--",
        "--#----#--",
        "--#----#--",
        "--#---=#--",
        "--#----#--",
        "--#----#--",
        "--#----#--",
        "--#----#--",
        "--#=---#--",
        "-------#-O",
        "-------#--",
        "##########",
        "##########"
    ],
    [
        "----------",
        "----------",
        "----------",
        "###-------",
        "####------",
        "#####-----",
        "######----",
        "#######---",
        "--------!!",
        "--------##",
        "-------###",
        "------####",
        "O----#####",
        "----######",
        "##########",
        "##########"
    ],
    [
        "----------",
        "----------",
        "----------",
        "########--",
        "----------",
        "----------",
        "----------",
        "---=------",
        "--!!!!!!!!",
        "--########",
        "----------",
        "----------",
        "---------O",
        "----------",
        "##-=----##",
        "##------##"
    ],
    [
        "##########",
        "##########",
        "----------",
        "#########-",
        "----------",
        "-#########",
        "----------",
        "--=-------",
        "!!!!!!!!!-",
        "#########-",
        "#########-",
        "#########-",
        "#########-",
        "#########O",
        "#########-",
        "##########"
    ],
    [
        "-#########",
        "-#=------#",
        "-#########",
        "-#-=-----#",
        "-#########",
        "-#--=----#",
        "-#########",
        "-#---=---#",
        "-#########",
        "-#########",
        "-#--------",
        "-#--------",
        "-#-------O",
        "---!-!-!--",
        "-#########",
        "----------"
    ],
    [
        "----------",
        "----------",
        "----------",
        "#---------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "-------O--",
        "----------",
        "-------#--"
    ],
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "----------",
        "#---#---#-",
        "!!!!!!!!#-",
        "#########-",
        "--------#-",
        "--------#-",
        "--------#-",
        "-O--#---#-",
        "--------#-",
        "-#--------",
        "-------###",
        "-------###"
    ],
    [
        "---#------",
        "---#------",
        "---#-!-!!-",
        "##-#-####-",
        "---#-#----",
        "-----#----",
        "!!!--#----",
        "######=--#",
        "----------",
        "----------",
        "----------",
        "----------",
        "O---------",
        "-------!!!",
        "####--####",
        "----------"
    ],
    [
        "!######!--",
        "#------#!-",
        "--####--#!",
        "##---!#-!#",
        "#--#-#--#-",
        "#--#---#--",
        "--#-###--!",
        "-#-!-!#--#",
        "---#-#--#-",
        "##-----##-",
        "--#-#-#---",
        "!!--#---!-",
        "##-######-",
        "---------O",
        "-=--------",
        "---------#"
    ],
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "-!-!-!-!--",
        "-#-#-#-#--",
        "-!-!---!--",
        "-#-#-#-#--",
        "---!-!-!--",
        "-#-#-#-#--",
        "-!-!-!----",
        "-#-#-#-#--",
        "-!---!-!-O",
        "-#-#-#-#--",
        "##########",
        "##########"
    ],
    [
        "----------",
        "----------",
        "----------",
        "----------",
        "--!----!--",
        "-!#!--!#!O",
        "-#-#--#-#-",
        "#!--##--!#",
        "-#!----!#-",
        "--#!--!#--",
        "---#!!#---",
        "----##----",
        "----------",
        "----------",
        "----------",
        "----------"
    ],
    [
        "----------",
        "----------",
        "####--####",
        "!!!-------",
        "###-##----",
        "=---##!---",
        "###-####--",
        "----####!-",
        "-=--#####-",
        "-!!!##----",
        "-#####-###",
        "----##----",
        "---O##---O",
        "!---##!---",
        "##########",
        "##########"
    ],
    [
        "---#------",
        "---#------",
        "---#-----O",
        "=--#------",
        "!!------##",
        "##------##",
        "##------##",
        "##------##",
        "##------##",
        "##------##",
        "##------##",
        "###----###",
        "###!--!###",
        "####--####",
        "####--####",
        "####--####"
    ],
]


    // [
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------",
    //     "----------"
    // ],
