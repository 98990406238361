import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./talkAiStyles.css"
import { characters } from '../characterList.js'
import { user } from '../user/userInfo.js'

export default function MainAi() {
    const { state } = useLocation();
    const { tag } = state;
    const [initialized, setInitialized] = useState(false);
    const [messages, setMessages] = useState([]);
    let [selectedCharacter, setSelectedCharacter] = useState({});

    useEffect(() => {
        if (!initialized) {
            loadCharacterModel();
            setInitialized(true);
        }
    }, [initialized, loadCharacterModel]);

    const TESTING = false;
    const modelTraining = false;
    let baseUrl = TESTING ? "http://localhost:5000" : "https://api.shadowgames.ca";
    // console.log("SERVER URL: " + baseUrl);
    
    // let selectedCharacter = undefined;

    function updateUI(selectedCharacter) {
        createMessage(selectedCharacter.intro, selectedCharacter.image);
        document.getElementById("mainImage").src = selectedCharacter.image;
        document.getElementById("chName").innerHTML = selectedCharacter.name;
    }
    

    function loadCharacterModel() {
        for (let i = 0; i < characters.length; i++) {
            if (characters[i].name.toLowerCase() === tag.toLowerCase()) {
                selectedCharacter = characters[i];
                setSelectedCharacter(characters[i])
            }
        }


        let data = {
            character: selectedCharacter,
            user: user
        };

        if (!modelTraining) {
            try {
                fetch(baseUrl + "/model", {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json"
                    }, 
                    body: JSON.stringify(data)
                });
            } catch (error) {
                console.log("ERROR IN THE MODEL SEND FUNCTION", error);
            }
    
        } else {
            try {
                fetch(baseUrl + "/trainModel", {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json"
                    }, 
                    body: JSON.stringify(data)
                });
            } catch (error) {
                console.log("ERROR IN THE MODEL TRAIN SEND FUNCTION", error);
            }
        }
        
        updateUI(selectedCharacter);
    }

    function createMessage(text, image) {
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages, { text: text, image: image }];
            return updatedMessages;
        });
    }
    

    async function generateResponse(text) {
        let data = {
            text: text
        };

        const response = await fetch(baseUrl + "/api", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            }, 
            body: JSON.stringify(data)
        });

        const body = await response.json();

        return body.result.answer;
    } 

    async function reply(text) {
        const input = document.getElementById("input");
        let response = await generateResponse(text);

        if (!response) response = "Sorry but I don't understand";

        let image = selectedCharacter.image;

        setTimeout(() => {
            createMessage(response, image);
            input.disabled = false;
            input.focus();
            input.placeholder = "Type a message...";
        }, 1000);
    }

    function sendMessage() {
        const input = document.getElementById("input");
        let text = input.value;
        input.value = "";

        if (text !== "") {
            input.placeholder = "";
            input.disabled = true;
            createMessage(text, user.image);
            reply(text);
        }
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevents the default Enter behavior (new line)
          sendMessage();
        }
      }

    return (
        <>
            <div className="mainAIPage">
                <div className="left">
                    <img src="./characters/ai/img.png" id="mainImage" alt="" />
                    <div className="buttonMenu">
                        <a href="/shadowai" className="material-symbols-outlined actionButton">home</a>
                        <div className="line"></div> 
                        <div className="nameBox " id="nameBox">
                            <h2 id="chName">NAME</h2>
                        </div>
                    </div>
                </div>

                <div className="right">
                    <div className="messages" id="messages">
                        {messages.map((message, i) => {
                            return (
                                <div key={i} className="message">
                                    <img src={message.image} alt="" />
                                    <h2>{message.text}</h2>
                                </div>
                            );
                        })}
                    </div>

                    <div className="textField">
                    <textarea autoFocus id="input" placeholder="Type a message..." name="text" spellCheck="false" className="input" onKeyDown={(event) => event.key === 'Enter' && sendMessage()}></textarea>
                        <button id="button" className="sendButton" onClick={sendMessage}><span className="material-symbols-outlined">send</span></button>
                    </div>
                </div>
            </div>
        </>
    );
}
