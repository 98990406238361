import React from "react";
import { useNavigate } from "react-router-dom";
import { characters } from "../characterList";

import './characterMenuStyles.css'

export default function CharacterMenu() {
    const navigate = useNavigate();
    function goto (tag: string): void {     
        navigate('/shadowai/aichat', { state: { tag } });
    }

    let categoryList = [];
    for (let i = 0; i < characters.length; i++) {
        if (!categoryList.includes(characters[i].category)) categoryList.push(characters[i].category)
    }

    return ( 
        <div className="characterMenuPage">

            <a className="backButton" href="/"><span class="material-symbols-outlined">arrow_back</span>Back to Shadow Games</a>
            
            <h1>SHADOW AI</h1>
            <div className="characterSelection">
                {categoryList.map((category, j) => {
                    return (
                        <>
                            <h2>{ category }</h2>
                            <div className="line"></div>
                            

                            <div className="categoryHolder" key={j}>
                                {characters.map((character, i) => {
                                    if (character.category === category) {
                                        return (
                                            <div className="itemHolder" key={i}> 
                                                <button onClick={() => goto(character.name)} className="item">
                                                        <img src={character.image} alt="opps" />
                                                        <div className="underImage">
                                                            <div className="bottomText">
                                                                <h2>{character.name}</h2>
                                                            </div>
                                                            {/* <div className="description">
                                                                <h4>{character.age}</h4>
                                                            </div> */}
                                                        </div>
                                                    </button>
                                            </div>   
                                        )
                                    } else {
                                        return <></>
                                    }
                                })}
                            </div> 

                        </>
                    )
                })}
            </div>

                
               
        </div>
    )
}

