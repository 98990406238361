let allInputs = document.querySelectorAll("input[type='password'], input[type='email'], input[type='text']")

for (let input of allInputs) {
    input.addEventListener("input", function () {
        if (this.value !== "") {
            this.classList.add("filled");
        } else {
            this.classList.remove("filled");
        }
    })
}