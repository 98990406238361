// let whole = document.querySelector(".showBox")
// let icon = document.querySelector(".showPassword")
// let passwordInput = document.getElementById("passwordInput") 

// let count = 1;
// whole.addEventListener("click", () => {
//     count += 1;

//     if (count % 2 === 0) {
//         icon.name = "eye-outline"
//         passwordInput.type = "text"
//     } else {
//         icon.name = "eye-off-outline"
//         passwordInput.type = "password"
//     }


// })


let allInputs = document.querySelectorAll("input[type='password'], input[type='email'], input[type='text']")

for (let input of allInputs) {
    input.addEventListener("input", function () {
        if (this.value !== "") {
            this.classList.add("filled");
        } else {
            this.classList.remove("filled");
        }
    })
}

