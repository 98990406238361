
export class TictactoeGame {

    constructor() {
        this.tableList = []
        this.turn = "X"
        this.size = 3;
        this.numberOfMovesMade = 0;
        this.gameWon = false;
        this.x_win = 0;
        this.o_win = 0;
    }

    updateScore() {
        const x = document.getElementById("xWin")
        const o = document.getElementById("oWin")
        x.innerHTML = `X Wins: ${this.x_win}`
        o.innerHTML = `O Wins: ${this.o_win}`
    }


    colorRed(one, two, three) {
        const uno = document.getElementById((one.x * 3) + one.y)
        const duo = document.getElementById((two.x * 3) + two.y)
        const tres = document.getElementById((three.x * 3) + three.y)

        uno.setAttribute("class", "red")
        duo.setAttribute("class", "red")
        tres.setAttribute("class", "red")
    }

    xWinGame() {
        this.x_win += 1;
        this.updateScore()
        this.gameWon = true;
    }

    oWinGame() {
        this.o_win += 1;
        this.updateScore()
        this.gameWon = true;
    }

    checkWin() {
        if (this.tableList[0][0].inside === "X" && this.tableList[0][1].inside === "X" && this.tableList[0][2].inside === "X") {
            this.colorRed(this.tableList[0][0], this.tableList[0][1], this.tableList[0][2])
            this.xWinGame()
        } else if (this.tableList[1][0].inside === "X" && this.tableList[1][1].inside === "X" && this.tableList[1][2].inside === "X") {
            this.colorRed(this.tableList[1][0], this.tableList[1][1], this.tableList[1][2])
            this.xWinGame()
        } else if (this.tableList[2][0].inside === "X" && this.tableList[2][1].inside === "X" && this.tableList[2][2].inside === "X") {
            this.colorRed(this.tableList[2][0], this.tableList[2][1], this.tableList[2][2])
            this.xWinGame()
        } else if (this.tableList[0][0].inside === "X" && this.tableList[1][0].inside === "X" && this.tableList[2][0].inside === "X") {
            this.colorRed(this.tableList[0][0], this.tableList[1][0], this.tableList[2][0])
            this.xWinGame()
        } else if (this.tableList[0][1].inside === "X" && this.tableList[1][1].inside === "X" && this.tableList[2][1].inside === "X") {
            this.colorRed(this.tableList[0][1], this.tableList[1][1], this.tableList[2][1])
            this.xWinGame()
        } else if (this.tableList[0][2].inside === "X" && this.tableList[1][2].inside === "X" && this.tableList[2][2].inside === "X") {
            this.colorRed(this.tableList[0][2], this.tableList[1][2], this.tableList[2][2])
            this.xWinGame()
        } else if (this.tableList[0][0].inside === "X" && this.tableList[1][1].inside === "X" && this.tableList[2][2].inside === "X") {
            this.colorRed(this.tableList[0][0], this.tableList[1][1], this.tableList[2][2])
            this.xWinGame()
        } else if (this.tableList[2][0].inside === "X" && this.tableList[1][1].inside === "X" && this.tableList[0][2].inside === "X") {
            this.colorRed(this.tableList[2][0], this.tableList[1][1], this.tableList[0][2])
            this.xWinGame()
        }

        
        else if (this.tableList[0][0].inside === "O" && this.tableList[0][1].inside === "O" && this.tableList[0][2].inside === "O") {
            this.colorRed(this.tableList[0][0], this.tableList[0][1], this.tableList[0][2])
            this.oWinGame()
        } else if (this.tableList[1][0].inside === "O" && this.tableList[1][1].inside === "O" && this.tableList[1][2].inside === "O") {
            this.colorRed(this.tableList[1][0], this.tableList[1][1],this.tableList[1][2])
            this.oWinGame()
        } else if (this.tableList[2][0].inside === "O" && this.tableList[2][1].inside === "O" && this.tableList[2][2].inside === "O") {
            this.colorRed(this.tableList[2][0], this.tableList[2][1], this.tableList[2][2])
            this.oWinGame()
        } else if (this.tableList[0][0].inside === "O" && this.tableList[1][0].inside === "O" && this.tableList[2][0].inside === "O") {
            this.colorRed(this.tableList[0][0], this.tableList[1][0], this.tableList[2][0])
            this.oWinGame()
        } else if (this.tableList[0][1].inside === "O" && this.tableList[1][1].inside === "O" && this.tableList[2][1].inside === "O") {
            this.colorRed(this.tableList[0][1], this.tableList[1][1], this.tableList[2][1])
            this.oWinGame()
        } else if (this.tableList[0][2].inside === "O" && this.tableList[1][2].inside === "O" && this.tableList[2][2].inside === "O") {
            this.colorRed(this.tableList[0][2], this.tableList[1][2], this.tableList[2][2])
            this.oWinGame()
        } else if (this.tableList[0][0].inside === "O" && this.tableList[1][1].inside === "O" && this.tableList[2][2].inside === "O") {
            this.colorRed(this.tableList[0][0], this.tableList[1][1], this.tableList[2][2])
            this.oWinGame()
        } else if (this.tableList[2][0].inside === "O" && this.tableList[1][1].inside === "O" && this.tableList[0][2].inside === "O") {
            this.colorRed(this.tableList[2][0], this.tableList[1][1], this.tableList[0][2])
            this.oWinGame()
        }

        if (this.numberOfMovesMade === (this.size * this.size)) {
            console.log("Tie")
        }
    }

    moveMade(box, x, y) {

        if (!this.gameWon) {

            this.numberOfMovesMade += 1;

            if (this.tableList[x][y].clicked === false) {
                this.tableList[x][y].clicked = true
                if (this.turn === "X") {
                    box.innerHTML = "X"
                    this.tableList[x][y].inside = "X"
                    this.turn = "O"
                } else {
                    box.innerHTML = "O"
                    this.tableList[x][y].inside = "O"
                    this.turn = "X"
                }

                this.checkWin(box)
            }
            }
    }

    craeteVirtualTable() {
        let row = []
        let info;
        for (let i = 0; i < this.size; i++) {
            for (let j = 0; j < this.size; j++) {
                
                let info = {
                    x: i,
                    y: j,
                    clicked: false,
                    inside: ""
                }

                row.push(info)
            }

            this.tableList.push(row)
            row = []

        }

        this.createTable(info)
    }

    createTable(info) {
        const table = document.getElementById("table");

        for (let i = 0; i < this.size; i++) {

            const row = document.createElement("tr");
            for (let j = 0; j < this.size; j++) {
                const box = document.createElement("th");
                box.setAttribute("class", "box")
                // box.setAttribute("class", "blackTable")
                box.setAttribute("id", (i * 3) + j)
                this.tableList.push(info)

                box.addEventListener("click", () => {
                    let x_cord = i
                    let y_cord = j
                    this.moveMade(box, x_cord, y_cord)
                })

                row.appendChild(box);
            }
            table.appendChild(row);
        }
    }

    restartGame() {
        let table = document.getElementById("table");

        if (table) {
            table.remove()
        }

        let tableHolder = document.getElementById("tableHolder");

        table = document.createElement("table")
        table.setAttribute("id", "table")
        table.setAttribute("class", "table")
        tableHolder.appendChild(table)
    
        this.tableList = []
        this.turn = "X"
        this.size = 3;
        this.numberOfMovesMade = 0;
        this.gameWon = false;
        this.craeteVirtualTable();
    }

    bindEvents() {
        document.onkeydown = (e) => {  
            if (e.key === "r" || e.key === "R") this.restartGame();   
        }
    }


    startGame() {
        if (this.isInitialized) return;
        else this.isInitialized = true;
        
        this.craeteVirtualTable()
        this.bindEvents() 
    }
}
