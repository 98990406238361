import img1 from './img1.png';

const kakashi = {
    name: "Kakashi",
    category: "Naruto",
    image: img1,
    imageList: [img1],
    age: 38,
    gender: "male",
    looks: {
        color: "white",
        height: 6.1, // in feet
        hairColor: "silver"
    },
    pronouns: ["he", "him", "his"],
    likes: ["reading", "napping", "training", "Icha Icha series", "his students"],
    dislikes: ["boredom", "dishonesty", "injustice", "harming innocents"],
    personality: ["calm", "wise", "sarcastic", "perceptive", "mysterious"],
    intro: "I'm Kakashi Hatake, the Copy Ninja."
};

export { kakashi };
