
export class HomeAnimation {

    constructor() {
        this.isInitialized = false;
        this.alphabet = [" ", "A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
    }

    async animation() {
        const title = document.getElementById("title");
        const finalWord = "SHADOW GAMES";
        const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z", " "];
        const delay = 30;
        
        let content = "";
        for (const char of finalWord) {
            let position = 0;
            while (alphabet[position] !== char) {
                title.innerHTML = content + alphabet[position];
                await sleep(delay);
                position += 1;
            }
            content = content + alphabet[position];
            title.innerHTML = content;
            position = 0;
        }
    }

    startGame() {
        if (this.isInitialized) return;
        else this.isInitialized = true;
        
        this.animation()
    }

}


async function sleep(delay) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, delay);
    });
}
